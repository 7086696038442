const Privacy = () => {
  return (
    <div className="min-h-screen bg-gray-50 overflow-x-hidden">
      {/* <div className="flex justify-center items-center border border-black rounded-lg max-w-[80px] ml-10 mt-10 h-[30px]">
        <a href="/#home" className="text-center text-[12px] text-[#187CFF] block no-underline">
          Go Home
        </a>
      </div> */}
      <main className="py-0 md:py-6 lg:py-6">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-lg p-8">
          <img
            src="/assets/main/rizzLogo.png"
            alt="Rizz Logo"
            className="w-[208px] h-[44px] mb-4"
          />
          <h1 className="text-lg font-semibold text-gray-900 mb-8 text-center">
            Terms of Service
          </h1>
          <p className="text-sm text-gray-600 mb-8">
            Effective Date: 12th December 2024
          </p>
          <section className="prose prose-lg max-w-none">
            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              Introduction
            </h2>
            <p className="text-gray-700 mb-6">
              Welcome to Rizz Software Solutions Private Limited (hereinafter
              referred to as "we," "our," or "us"). We are a software product
              and services company based in Chennai, India, primarily focused on
              healthcare technology. By using our Websites, Mobile Applications,
              APIs, Products, and Services (collectively, the "Platform"), you
              agree to these Terms of Service ("Terms"). If you do not agree to
              these Terms, you should not use the Platform.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              Company Information
            </h2>
            <div className="bg-gray-50 p-6 rounded-lg mb-6">
              <p className="text-[#2b165e]">
                Rizz Software Solutions Private Limited
                <br />
                Level 8 & 9 Olympia Teknos,
                <br />
                No. 28 SIDCO Industrial Estate,
                <br />
                Guindy, Chennai 600032, India
              </p>
            </div>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              Our Commitment to Privacy
            </h2>
            <p className="text-gray-700 mb-6">
              Your privacy is our top priority. For detailed information about
              how we collect, use, and protect your data, please refer to our
              Privacy Policy.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              Use of the Platform
            </h2>
            <div className="space-y-4">
              <div>
                <h3 className="text-sm font-semibold text-gray-900 mb-2">
                  1. Eligibility
                </h3>
                <p className="text-gray-700">
                  Users below the age of 13 cannot directly register or use the
                  Platform. Parents or guardians must review these Terms and
                  provide verifiable consent for their children to access the
                  Platform.
                </p>
              </div>
              <div>
                <h3 className="text-sm font-semibold text-gray-900 mb-2">
                  2. Scope of Services
                </h3>
                <p className="text-gray-700">
                  The Platform serves as a facilitator between users and
                  healthcare providers such as hospitals, physicians, and
                  pharmacies. We do not provide medical advice or diagnose
                  medical conditions. Our services are not a substitute for
                  professional medical consultation, diagnosis, or treatment.
                </p>
              </div>
              <div>
                <h3 className="text-sm font-semibold text-gray-900 mb-2">
                  3. User Obligations
                </h3>
                <p className="text-gray-700">
                  You agree to receive text messages via SMS and notifications
                  as part of our services. You are responsible for providing
                  accurate and up-to-date information.
                </p>
              </div>
            </div>
            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              Fees and Refunds
            </h2>
            <p className="text-gray-700 mb-6">
              Some products and services on our Platform may be chargeable,
              while others are offered free of cost. Please refer to the
              specific platform or product website for information about fees
              and the applicable refund policy.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              Changes to Terms of Service
            </h2>
            <p className="text-gray-700 mb-6">
              We reserve the right to modify these Terms at any time. Continued
              use of the Platform after changes are posted constitutes
              acceptance of the updated Terms.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              Liability Disclaimer
            </h2>
            <div className="space-y-4">
              <div>
                <h3 className="text-sm font-semibold text-gray-900 mb-2">
                  1. Facilitator Role
                </h3>
                <p className="text-gray-700">
                  The Platform is a facilitator and not responsible for any
                  untoward events that may occur.
                </p>
              </div>
              <div>
                <h3 className="text-sm font-semibold text-gray-900 mb-2">
                  2. Medical Advice
                </h3>
                <p className="text-gray-700">
                  We do not provide medical advice or services. Users should
                  consult a qualified healthcare professional for medical
                  concerns.
                </p>
              </div>
            </div>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              EMAIL AND TEXT MESSAGING
            </h2>
            <p className="text-gray-700 mb-6">
              • You understand and agree that you may receive information and
              notifications from Rizz Software Solutions Private Limited via
              text messaging or through email subscription. You hereby consent
              to receive communications via text message, calls to your mobile
              number, or emails. You acknowledge that you may incur additional
              charges or fees from your wireless provider for these
              communications, including text message charges and data usage
              fees, and you acknowledge and agree that you are solely
              responsible for any such charges and fees and not us.
            </p>
            <p className="text-black mb-6">
              <span className="text-black mb-6 font-semibold">
                ◦ Email Subscription:
              </span>{" "}
              You can always unsubscribe from our commercial or promotional
              emails, but we will still send you transactional and relational
              emails about your Account or use of our Website. If you want to
              unsubscribe from receiving promotional and commercial email
              notifications from us, please send us an email at
              Unsubscribe@rizzsoftware.com.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              DISCLAIMER OF WARRANTIES
            </h2>

            <p className="text-gray-700 mb-6">
              • Your access to and use of the Website or any content are at your
              own risk. You understand and agree that the Website is provided to
              you on an “AS IS” and “AS AVAILABLE” basis. Without limiting the
              foregoing, to the maximum extent permitted under applicable law,
              Rizz Software Solutions Private Limited DISCLAIMS ALL WARRANTIES
              AND CONDITIONS, WHETHER EXPRESSED OR IMPLIED, OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. we makes no
              warranty or representation and disclaims all responsibility and
              liability for:
              <span className="text-[13px] text-gray">
                <br /> ◦ The completeness, accuracy, availability, timeliness,
                security, or reliability of the Website or any content; ◦ Any
                harm to your computer system, loss of data, or other harm that
                results from your access to or use of the Website or any
                content; <br />◦ The deletion of, or the failure to store or to
                transmit, any content and other communications maintained by the
                Website; and
                <br /> ◦ Whether the Website will meet your requirements or be
                available on an uninterrupted, secure, or error-free basis.{" "}
                <br />• No advice or information, whether oral or written,
                obtained from Rizz Software Solutions Private Limited or through
                the Website, will create any warranty or representation not
                expressly made herein.
              </span>
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              INDEMNITY
            </h2>
            <p className="text-gray-700 mb-6">
              You shall indemnify, defend, and hold Rizz Software Solutions
              Private Limited and our officers, employees, managers, directors,
              customers, and agents (the “Indemnified Parties”) harmless from
              and against any and all costs, liabilities, losses, and expenses
              (including but not limited to reasonable attorneys’ fees)
              resulting from any claim, suit, action, demand, or proceeding
              brought by any third party against Rizz Software Solutions Private
              Limited Indemnified Parties arising from any of the following:
            </p>
            <ul className="text-[14px]">
              <li>◦ A breach of these User Terms;</li>
              <li>
                ◦ The negligence, gross negligence, or willful misconduct of you
                or your employees, agents, or contractors;
              </li>
              <li>
                {" "}
                ◦ Incorrect information provided by you in your Account or
                elsewhere; or
              </li>
              <li>
                ◦ A failure by you or your employees, agents, contractors, or
                invitees to comply with applicable laws and regulations.
              </li>
            </ul>
            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              LIMITATION OF LIABILITY
            </h2>
            <p className="text-gray-700 mb-6">
              MITATION OF LIABILITY • TO THE MAXIMUM EXTENT PERMITTED BY
              APPLICABLE LAW, Rizz Software Solutions Private Limited SHALL NOT
              BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
              PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER
              INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE,
              GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM
            </p>

            <ul className="text-[14px]">
              <li>
                ◦ YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE
                WEBSITE;
              </li>
              <li>
                ◦ ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE WEBSITE,
                INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR
                ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES;
              </li>
              <li> ◦ ANY CONTENT OBTAINED FROM THE WEBSITE; OR</li>
              <li>
                ◦ UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR TRANSMISSIONS
                OR CONTENT.
              </li>
            </ul>

            <p className="text-gray-700 text-[13px] mb-6">
              IN NO EVENT SHALL THE AGGREGATE LIABILITY OF Rizz Software
              Solutions Private Limited EXCEED THE GREATER OF ONE HUNDRED U.S.
              DOLLARS (U.S. $100.00) OR THE AMOUNT YOU PAID Rizz Software
              Solutions Private Limited, IF ANY, IN THE PAST SIX MONTHS FOR
              ACCESS TO THE WEBSITE, GIVING RISE TO THE CLAIM. THE LIMITATIONS
              OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF LIABILITY, WHETHER
              BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE)
              OR OTHERWISE, AND WHETHER OR NOT Rizz Software Solutions Private
              Limited HAS BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE,
              AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF
              ITS ESSENTIAL PURPOSE.
            </p>
            <p className="text-gray-700 text-[13px] mb-6">
              YOU AND (IF APPLICABLE) YOUR PERSONAL REPRESENTATIVE, KNOWINGLY
              AND FREELY ASSUME ALL RISK WHEN ACCESSING THE WEBSITE. YOU, ON
              BEHALF OF YOURSELF, AND (IF APPLICABLE) YOUR PERSONAL
              REPRESENTATIVES AND YOUR HEIRS, HEREBY VOLUNTARILY AGREE TO
              RELEASE, WAIVE, DISCHARGE, HOLD HARMLESS, DEFEND, AND INDEMNIFY
              Rizz Software Solutions Private Limited AND ITS OFFICERS,
              DIRECTORS, EMPLOYEES, CONSULTANTS, AGENTS, SUCCESSORS, AND
              ASSIGNS, FROM AND AGAINST ANY CLAIMS, DISPUTES, DEMANDS,
              LIABILITIES, DAMAGES, LOSSES, AND COSTS AND EXPENSES, INCLUDING,
              WITHOUT LIMITATION, REASONABLE LEGAL AND ACCOUNTING FEES ARISING
              OUT OF OR IN ANY WAY CONNECTED WITH
            </p>

            <ul className="text-[14px] ">
              <li className="mb-2">◦ YOUR ACCESS TO OR USE OF THE WEBSITE</li>
              <li>
                ◦ YOUR USE OF THE WEBSITE, INCLUDING, WITHOUT LIMITATION, FOR
                BODILY INJURY, WRONGFUL DEATH, EMOTIONAL DISTRESS, OR OTHER
                DAMAGES OR HARM, WHETHER TO YOU OR TO THIRD PARTIES, WHICH MAY
                RESULT FROM THE USE OF THE WEBSITE,
              </li>
              <li className="mb-2">
                ◦ VIOLATION OF THESE TERMS, INCLUDING, WITHOUT LIMITATION, YOUR
                BREACH OF ANY OF THE REPRESENTATIONS AND WARRANTIES CONTAINED
                HEREIN AND FOR BODILY INJURY, WRONGFUL DEATH, EMOTIONAL
                DISTRESS, LOSS OF SERVICES OR OTHER DAMAGES OR HARM, WHETHER TO
                YOU OR TO THIRD PARTIES, WHICH MAY RESULT FROM YOUR USE OF THE
                WEBSITE,
              </li>
              <li className="mb-2">
                ◦ CLAIMS, OR ANY DECISION BY A COURT, ARBITRATOR, OR GOVERNMENT
                AGENCY, THAT Rizz Software Solutions Private Limited IS
                OBLIGATED TO PAY ANY WITHHOLDING TAXES, SOCIAL SECURITY,
                UNEMPLOYMENT OR DISABILITY INSURANCE OR SIMILAR ITEMS IN
                CONNECTION WITH ANY PAYMENT RECEIVED BY YOU UNDER THE TERMS,
              </li>
              <li className="mb-2">
                ◦ YOUR VIOLATION OF ANY THIRD-PARTY RIGHT, INCLUDING WITHOUT
                LIMITATION ANY RIGHT OF PRIVACY OR INTELLECTUAL PROPERTY RIGHTS,
              </li>
              <li className="mb-2">
                ◦ YOUR VIOLATION OF ANY APPLICABLE LAW, RULE, OR REGULATION,
              </li>
              <li className="mb-2">◦ YOUR WILLFUL MISCONDUCT, OR</li>
              <li className="mb-2">
                ◦ ANY OTHER PARTY’S ACCESS AND USE OF THE WEBSITE WITH YOUR
                UNIQUE USERNAME, PASSWORD OR OTHER APPROPRIATE SECURITY CODE.
              </li>
            </ul>
            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              RESTRICTIONS
            </h2>
            <p className="text-gray-700 mb-6">
              • You agree that the Services, including but not limited to the
              Website, App, graphics, trademarks, and editorial content, contain
              proprietary content, information, and material owned by Rizz
              Software Solutions Private Limited and/or its licensors, including
              our customers, brands, and agencies, and is protected by
              applicable intellectual property and other laws, including but not
              limited to copyright. You agree that you will not use such
              proprietary content, information, or materials other than for your
              permitted use of the Services or in any manner that is
              inconsistent with these User Terms.
            </p>
            <p className="text-gray-700 mb-6">
              • You agree not to modify, rent, lease, loan, sell, distribute, or
              create derivative works based on the Services in any manner, and
              you shall not exploit the Services in any unauthorized way
              whatsoever, including but not limited to, using the Services to
              transmit any computer viruses, worms, Trojan horses, or other
              malware, or by trespass or burdening network capacity. You further
              agree not to use the Services in any manner to harass, abuse,
              stalk, threaten, defame, or otherwise infringe or violate the
              rights of any other party, and that Rizz Software Solutions
              Private Limited is not in any way responsible for any such use by
              you, nor for any harassing, threatening, defamatory, offensive,
              infringing, or illegal messages or transmissions that you may
              receive as a result of using the Services.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              FORCE MAJEURE
            </h2>
            <p className="text-gray-700 mb-6">
              • Neither Rizz Software Solutions Private Limited nor you shall be
              responsible for damages or for delays or failures in performance
              resulting from acts or occurrences beyond their reasonable
              control, including, without limitation: fire, lightning,
              explosion, power surge or failure, water, acts of God, war,
              revolution, civil commotion or acts of civil or military
              authorities or public enemies; any law, order, regulation,
              ordinance, or requirement of any government or legal body or any
              representative of any such government or legal body; or labor
              unrest, including without limitation, strikes, slowdowns,
              picketing, or boycotts; inability to secure raw materials, food
              preparation facilities, fuel or energy shortages, or acts or
              omissions of other common carriers.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              DISPUTE RESOLUTION AND ARBITRATION
            </h2>

            <p className="text-gray-700 mb-6">
              • PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES
              YOU TO ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH Rizz Software
              Solutions Private Limited AND LIMITS THE MANNER IN WHICH YOU CAN
              SEEK RELIEF FROM US.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              a. Binding Arbitration
            </h2>

            <p className="text-gray-700 mb-6">
              • Except for any disputes, claims, suits, actions, causes of
              action, demands or proceedings (collectively, “Disputes”) in which
              either party seeks to bring an individual action in small claims
              court or seeks injunctive or other equitable relief for the
              alleged unlawful use of intellectual property, including, without
              limitation, copyrights, trademarks, trade names, logos, trade
              secrets or patents, you and Rizz Software Solutions Private
              Limited agree:
            </p>
            <ul className="text-[13px]">
              <li className="mb-2">
                1 To waive your and Rizz Software Solutions Private Limited’
                respective rights to have any and all Disputes arising from or
                related to this Agreement, use of our Website, resolved in a
                court.
              </li>
              <li className="mb-2">
                2 To waive your and Rizz Software Solutions Private Limited’
                respective rights to a jury trial. Instead, you and Rizz
                Software Solutions Private Limited agree to arbitrate Disputes
                through binding arbitration.
              </li>
            </ul>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              b. No Class Arbitrations, Class Actions or Representative Actions
            </h2>
            <p className="text-gray-700 mb-6">
              • You and Rizz Software Solutions Private Limited agree that any
              Dispute arising out of or related to these User Terms or the
              Website is personal to you and Rizz Software Solutions Private
              Limited and that such Dispute will be resolved solely through
              individual arbitration and will not be brought as a class
              arbitration, class action, or any other type of representative
              proceeding.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              c. Federal Arbitration Act
            </h2>
            <p className="text-gray-700 mb-6">
              • You and Rizz Software Solutions Private Limited agree that these
              Terms affect interstate commerce and that the enforceability of
              this Section shall be both substantively and procedurally governed
              by and construed and enforced in accordance with the Federal
              Arbitration Act (FAA).
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              d. Notice; Informal Dispute Resolution
            </h2>
            <p className="text-gray-700 mb-6">
              • You and Rizz Software Solutions Private Limited agree to notify
              each other in writing of any arbitrable or small claims Dispute
              within 30 days of its occurrence. Notices to Rizz Software
              Solutions Private Limited must include:
            </p>
            <ul className="text-[13px]">
              <li className="mb-2">1 Your contact information.</li>
              <li className="mb-2">
                {" "}
                2 A description of the nature of the Dispute.
              </li>
              <li className="mb-2"> 3 The specific relief sought.</li>
            </ul>
            <p className="text-gray-700 mb-6">
              • If the Dispute is not resolved within 30 days, either party may
              commence arbitration.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              e. Process
            </h2>
            <p className="text-gray-700 mb-6">
              • Arbitration will occur in Chennai, India, conducted
              confidentially by a single arbitrator in accordance with the
              Arbitration and Conciliation Act, 1996.
            </p>
            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              f. Authority of Arbitrator
            </h2>
            <p className="text-gray-700 mb-6">
              • The arbitrator has the exclusive authority to make all
              procedural and substantive decisions but cannot conduct class
              arbitrations.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              g. Severability
            </h2>
            <p className="text-gray-700 mb-6">
              • If any term in this section is deemed invalid, the remaining
              terms will remain enforceable.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              h. Right to Opt-Out
            </h2>
            <p className="text-gray-700 mb-6">
              • You may opt-out of arbitration within 30 days by providing
              written notice to Rizz Software Solutions Private Limited.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              MISCELLANEOUS
            </h2>
            <p className="text-gray-700 mb-6">
              • These Terms and the Privacy Policy constitute the sole and
              entire agreement between You and Rizz Software Solutions Private
              Limited (collectively “Agreement”) with respect to the Website and
              supersede all prior and contemporaneous understandings,
              agreements, representations, and warranties, both written and
              oral, with respect to the Website. Our failure to exercise or
              enforce any right or provision of this Agreement will not
              constitute a waiver of such right or provision. If any provision
              of this Agreement is found by a court of competent jurisdiction to
              be invalid, we both nevertheless agree that the court should
              endeavor to give effect to our intentions as reflected in the
              provision, and the other provisions of this Agreement remain in
              full force and effect. You agree that regardless of any statute or
              law to the contrary, any claim or cause of action arising out of
              or related to use of the Website, or this Agreement must be filed
              within one (1) year after such claim or cause of action arose or
              be forever barred. A party’s failure to act with respect to a
              breach by the other party does not constitute a waiver of the
              party’s right to act with respect to subsequent or similar
              breaches. All the sections intended to survive the termination of
              this Agreement shall survive. The section titles in this Agreement
              are for convenience only and have no legal or contractual effect.
              Except as explicitly stated otherwise, any notices to Rizz
              Software Solutions Private Limited shall be given by certified
              mail, postage prepaid and return receipt requested to Rizz
              Software Solutions Private Limited, Level 8 & 9, Olympia Teknos,
              No 28, SIDCO Industrial Estate, Guindy Industrial Estate, Chennai,
              Tamil Nadu, India, 600032. Any notices to you shall be provided to
              you through our Website or given to you via the email address or
              physical address you provide Rizz Software Solutions Private
              Limited during the registration process.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              Governing Law
            </h2>
            <p className="text-gray-700 mb-6">
              These Terms are governed by and construed in accordance with the
              laws of India. Any disputes arising out of or related to these
              Terms shall be subject to the exclusive jurisdiction of the courts
              located in Chennai, India.
            </p>

            <h2 className="text-lg font-semibold text-gray-900 mt-8 mb-4">
              Contact Us
            </h2>
            <div className="bg-gray-50 p-6 rounded-lg">
              <p className="text-gray-700">
                <strong className="text-[#301b61]">
                  Rizz Software Solutions Private Limited
                </strong>
                <br />
                Level 8 & 9 Olympia Teknos,
                <br />
                No. 28 SIDCO Industrial Estate,
                <br />
                Guindy, Chennai 600032, India
                <br />
                <strong>Email:</strong> support@rizzsoftware.com
                <br />
                <br />
                <div class="flex flex-col">
                  <strong class="text-lg font-semibold">Phone:</strong>
                  <span class="text-gray-700">
                    +91 9445318886, +91 9445318887
                  </span>
                </div>
                <br />
              </p>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default Privacy;
