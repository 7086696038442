import React, { useState } from "react";

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <nav className="bg-white fixed top-0  w-full p-2 sm:p-4 md:p-6 lg:p-10 z-50 shadow-lg overflow-x-hidden">
      <div className="max-w-7xl mx-auto flex justify-between items-center px-4 md:px-8">
        <div>
          <a href="/">
            <img
              src="/assets/main/rizzLogo.png"
              alt="Rizz Logo"
              className="w-[208px] h-[44px]"
            />
          </a>
        </div>

        <div className="hidden md:flex space-x-8">
          <a
            href="#home"
            className="font-montserrat text-[#808285] font-bold hover:text-gray-700 no-underline"
          >
            Home
          </a>
          <a
            href="#products"
            className="font-montserrat text-[#808285] font-bold hover:text-gray-700 no-underline"
          >
            Products
          </a>
          <a
            href="#about"
            className="font-montserrat text-[#808285] font-bold hover:text-gray-700 no-underline"
          >
            About
          </a>
          <a
            href="#services"
            className="font-montserrat text-[#808285] font-bold hover:text-gray-700 no-underline"
          >
            Services
          </a>
          <a
            href="#mission"
            className="font-montserrat text-[#808285] font-bold hover:text-gray-700 no-underline"
          >
            Mission & Vision
          </a>
          <a
            href="#contact"
            className="font-montserrat text-[#808285] font-bold hover:text-gray-700 no-underline"
          >
            Contact
          </a>
        </div>

        <div className="md:hidden flex items-center justify-center">
          <button
            className="text-[#3a0866] hover:text-[#3a0866] focus:outline-none"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle navigation menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={
                  isMobileMenuOpen
                    ? "M6 18L18 6M6 6l12 12"
                    : "M4 6h16M4 12h16M4 18h16"
                }
              />
            </svg>
          </button>
        </div>
      </div>

      <div
        className={`md:hidden transition-all duration-300 ease-in-out ${
          isMobileMenuOpen ? "max-h-screen" : "max-h-0 overflow-hidden"
        }`}
      >
        <div className="bg-white shadow-md mt-4 pr-2">
          <a
            href="#home"
            className="block px-4 py-2 font-montserrat text-[#5f1a9c] hover:bg-gray-100 no-underline"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Home
          </a>
          <a
            href="#products"
            className="block px-4 py-2 font-montserrat text-[#5f1a9c] hover:bg-gray-100 no-underline"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Products
          </a>
          <a
            href="#about"
            className="block px-4 py-2 font-montserrat text-[#5f1a9c] hover:bg-gray-100 no-underline"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            About
          </a>
          <a
            href="#services"
            className="block px-4 py-2 font-montserrat text-[#5f1a9c] hover:bg-gray-100 no-underline"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Services
          </a>
          <a
            href="#mission"
            className="block px-4 py-2 font-montserrat text-[#5f1a9c] hover:bg-gray-100 no-underline"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Mission & Vision
          </a>
          <a
            href="#contact"
            className="block px-4 py-2 font-montserrat text-[#5f1a9c] hover:bg-gray-100 no-underline"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Contact
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
