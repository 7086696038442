import { LuAmpersand } from "react-icons/lu";

const Mission = () => {
  const data = [
    {
      id: 1,
      title: "Innovation",
      sub: "Crafting forward-thinking solutions that redefine healthcare.",
    },
    {
      id: 2,
      title: "Integrity",
      sub: "Maintaining transparency, ethical practices, and unwavering trust.",
    },
    {
      id: 3,
      title: "Privacy",
      sub: "Protecting user data with the highest standards of security and consent.",
    },
    {
      id: 4,
      title: "Care",
      sub: "Empowering healthcare providers and enriching patient lives through technology.",
    },
  ];

  return (
    <div className="px-4 ">
      <div className="w-screen w-full h-screen flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-10  mb-30">
        <div className="text-left md:text-left lg:text-left">
          <p className="text-[24px] ml-8 md:text-[36px] font-semibold font-playfair text-[#187CFF] flex items-center  md:justify-start">
            Our Mission
            <i className="mx-2">
              <LuAmpersand />
            </i>
            Vision
          </p>

          <div className="px-4 mt-12 space-y-4">
            <p className="text-[12px] mb-8 md:text-base lg:text-base max-w-[400px] md:max-w-[600px] lg:max-w-[500px] font-semibold font-montserrat md:text-left lg:text-left">
              Mission: To revolutionize healthcare by delivering innovative
              patient-centric solutions that enhance care quality and
              operational efficiency.
            </p>
            <p className="text-[12px]  md:text-base lg:text-base max-w-[400px] md:max-w-[600px] lg:max-w-[580px] font-semibold font-montserrat md:text-left lg:text-left">
              Vision: To lead the healthcare technology space with secure,{" "}
              <br />
              efficient and transformative solutions that improve patient <br />
              outcomes and provider success.
            </p>
          </div>
        </div>

        <div className="">
          <img
            src="/assets/main/candid2.png"
            alt="group"
            className="h-[207px] w-[294px] md:h-60 md:w-96 mx-auto md:mx-0 mt-24"
          />
        </div>
      </div>

      {/* Core Values Section */}
      <div className="flex flex-col bg-[#E9E9F44D] w-screen w-full py-20  p-8 justify-center items-center">
        <p className="text-[24px] md:text-[36px] font-semibold font-playfair text-center">
          Core Values
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8 mb-10 max-w-[1100px]">
          {data.map((item) => (
            <div
              key={item.id}
              className="bg-white rounded-[25px] shadow-xl mx-4 flex flex-col p-4 space-y-1"
            >
              <p className="text-left font-rubik font-bold">{item.title}</p>
              <p className="text-sm font-semibold font-montserrat">
                {item.sub}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mission;
